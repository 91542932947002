import numeral from 'numeral';

/**
 * @see https://github.com/adamwdraper/Numeral-js/blob/master/src/formats/bytes.js
 * Since this isn't configurable currently, this is defining a new type under 'ub' to show
 * decimal units with a binary calculation
 *
 * Would request and implement this feature, but the numeral node module is no longer maintained.
 */

var decimal = {
    base: 1000,
    suffixes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  },
  binary = {
    base: 1024,
    suffixes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  };

var allSuffixes =  decimal.suffixes.concat(binary.suffixes.filter(function (item) {
  return decimal.suffixes.indexOf(item) < 0;
}));
var unformatRegex = allSuffixes.join('|');
// Allow support for BPS (http://www.investopedia.com/terms/b/basispoint.asp)
unformatRegex = '(' + unformatRegex.replace('B', 'B(?!PS)') + ')';

numeral.register('format', 'mixedBytes', {
  regexps: {
    format: /([0\s]u?b)/,
    unformat: new RegExp(unformatRegex)
  },
  format: function(value, format, roundingFunction) {
    var output,
      bytes = numeral._.includes(format, 'ub') ? binary : decimal,
      suffix = numeral._.includes(format, ' b') || numeral._.includes(format, ' ub') ? ' ' : '',
      power,
      min,
      max;

    // check for space before
    format = format.replace(/\s?u?b/, '');

    for (power = 0; power <= bytes.suffixes.length; power++) {
      min = Math.pow(bytes.base, power);
      max = Math.pow(bytes.base, power + 1);

      if (value === null || value === 0 || value >= min && value < max) {
        suffix += bytes.suffixes[power];

        if (min > 0) {
          value = value / min;
        }

        break;
      }
    }

    output = numeral._.numberToFormat(value, format, roundingFunction);

    return output + suffix;
  },
  unformat: function(string) {
    var value = numeral._.stringToNumber(string),
      power,
      bytesMultiplier;

    if (value) {
      for (power = decimal.suffixes.length - 1; power >= 0; power--) {
        if (numeral._.includes(string, decimal.suffixes[power])) {
          bytesMultiplier = Math.pow(decimal.base, power);

          break;
        }

        if (numeral._.includes(string, binary.suffixes[power])) {
          bytesMultiplier = Math.pow(binary.base, power);

          break;
        }
      }

      value *= (bytesMultiplier || 1);
    }

    return value;
  }
});