import { createStore, applyMiddleware } from 'redux';
import delayedJobReducer from '../reducers/delayedJobReducer';
import ReduxThunk from 'redux-thunk';
import cableMiddleware from "../../../middleware/cableMiddleware";

export default railsProps => {
  const createStoreWithMiddleware = applyMiddleware(ReduxThunk, cableMiddleware())(createStore);

  return createStoreWithMiddleware(
    delayedJobReducer,
    railsProps,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
};
