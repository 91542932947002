import axios from 'axios';

export const replaceHTMLFromResponse = (replaceURL, replaceID) => {
  if (!replaceURL || !replaceID) {
    return;
  }

  axios
    .get(replaceURL, { headers: { Accept: 'text/javascript' } })
    .then(response => {
      let container = document.getElementById(replaceID);
      document.getElementById(replaceID).innerHTML = response.data;
      $(`#${replaceID}`).trigger("HTMLReplaced");
    })
    .catch(error => {
      location.reload();
    });
};