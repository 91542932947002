import api from '../../../services/api';
import {FETCHED_JOBS, FETCHING_JOBS, NEW_JOB, UPDATING_JOB, FINISHED_JOB} from '../constants/delayedJobConstants';
import {ACTION_CABLE_ACTION} from "../../../middleware/cableMiddleware";

/**
 * A map of DelayedJob statuses to dispatch action types.
 * Note: Added a fake status: finished - Since the job needs to be removed from the store when finished.
 *
 * @type {{running: string, enqueued: string, finished: string, failed: string, failed_obsolete: string}}
 */
const statusToJobStatus = {
  enqueued: NEW_JOB,
  running: UPDATING_JOB,
  failed: UPDATING_JOB,
  failed_obsolete: FINISHED_JOB,
  finished: FINISHED_JOB,
}

/**
 * Subscribes to the JobsChannel, this should be called when the component is ready to open a websocket connection.
 * When receiving the job, this action will convert the status to a dispatch type and send it to the reducers.
 *
 * Calls fetchJobs when connecting and reconnecting.
 *
 * @param fetchJobs { function | undefined }
 * @returns {function(function): *}
 */
export function subscribeJob(fetchJobs) {
  return dispatch => dispatch({
    type: ACTION_CABLE_ACTION,
    channel: 'JobsChannel',
    room: undefined,
    received: (data) => dispatch({ type: statusToJobStatus[data.status], job: data }),
    connected: fetchJobs
  });
}

/**
 * Unsubscribes from the JobsChannel. Should be using the published_url as that was used to subscribe.
 *
 * @param published_url { string | undefined }
 * @returns {function(function): *}
 */
export function unsubscribeJob(published_url) {
  return dispatch => dispatch({
    type: ACTION_CABLE_ACTION,
    channel: 'JobsChannel',
    room: published_url,
    leave: true,
  });
}

/**
 *  Queries the JobStatesController for jobs.
 *  If params includes published_url, then only the jobs related to that url will be fetched.
 *
 *  These jobs are then dispatched back to the reducer.
 *
 * @param params {object}
 * @returns {(function(function, function): void)|*}
 */
export const fetchJobs = (params = {}) => {
  return (dispatch, getState) => {
    if (getState().fetching) return;

    dispatch({ type: FETCHING_JOBS });
    api()
      .get('/job_states', { params })
      .then(response => dispatch({ type: FETCHED_JOBS, payload: response.data.jobs }));
  };
};
