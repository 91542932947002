import PropTypes from 'prop-types';
import React from 'react';
import Label from './Label';
import LoadingPanel from './LoadingPanel';
import { replaceHTMLFromResponse } from '../actions/htmlReplacer';
import { replaceWindowPropertyFromResponse } from '../actions/windowPropertyReplacer';

/**
 * Polls for pages that do not require a user (which will use a publishedURL)
 * Uses Websockets for pages without a publishedURL.
 */
export default class JobLabel extends React.Component {
  static defaultProps = {
    objects: [],
    loadingText: '',
    loadedText: '',
    failedText: '',
  };

  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(window.location.search);
    const publishedUrl = queryParams.get('published_url');
    this.state = publishedUrl ? { publishedUrl, polling: true, sockets: false } : { polling: false, sockets: true};
    this.jobFetchTimer = null;

    if (this.state.polling) {
      this.jobFetchTimer = setInterval(() => props.fetchJobs({ published_url: this.state.publishedUrl }), 8000);
    }
  }

  componentDidMount() {
    if (this.state.sockets) this.props.subscribeJob(this.props.fetchJobs);
  }

  componentWillUnmount() {
    if(this.state.polling) clearInterval(this.jobFetchTimer);
    if(this.state.sockets) this.props.unsubscribeJob(this.state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.job && !this.props.job) {
      replaceHTMLFromResponse(this.props.replaceURL, this.props.replaceId);
      replaceWindowPropertyFromResponse(this.props.windowPropertyDataURL, this.props.windowPropertyName);
      if(this.state.polling) clearInterval(this.jobFetchTimer);
    }
  }

  render() {
    return this.props.loadingText.length ? (
      <LoadingPanel
        job={this.props.job}
        showJobLink={this.props.showJobLink}
        loadingText={this.props.loadingText}
        loadedText={this.props.loadedText}
        failedText={this.props.failedText}
      />
    ) : (
      <Label
        job={this.props.job}
        primaryObjectType={this.props.primaryObjectType}
        primaryObjectId={this.props.primaryObjectId}
        showJobLink={this.props.showJobLink}
      />
    );
  }
}
