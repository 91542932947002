import {FETCHED_JOBS, FETCHING_JOBS, FINISHED_JOB, NEW_JOB, UPDATING_JOB} from '../constants/delayedJobConstants';

/**
 * Current list of jobs being tracked by the jobs store.
 * When receiving a payload of jobs from a fetch, this reducer clears the store and use the fetched jobs. (Hard update)
 * When receiving a single job, this reducer will add/update/remove that job according to the type passed by dispatch.
 *
 * @param state {Object}
 * @param state.jobs {[{id: number, object_global_id: string, status: string, friendly_error: string, class_name: string}]} This is an array of job objects.
 * @param state.fetching {Boolean=} Currently fetching jobs.
 * @param state.changes {Object=} Changes since trying to fetch the jobs
 * @param action {{
 *                  type: string,
 *                  payload: [{id: number, object_global_id: string, status: string, friendly_error: string, class_name: string}] | undefined,
 *                  job: {id: number, object_global_id: string, status: string, friendly_error: string, class_name: string} | undefined
 *               }} Dispatch action, including all fetched jobs as a payload or a single job with intent to add/update/remove a job.
 * @return state {Object} The new state
 */
export default (state = { fetching: false, jobs: [] }, action) => {
  switch (action.type) {
    case FETCHING_JOBS: return { ...state, fetching: true, changes: { created: [], updated: [], finished: [] } };
    case FETCHED_JOBS:  return fetchedJobs(state, action.payload);
    case NEW_JOB:       return addJob(state, action.job);
    case UPDATING_JOB:  return updateJob(state, action.job);
    case FINISHED_JOB:  return finishJob(state, action.job);
    default: return state;
  }
};

const fetchedJobs = (state, incomingJobs) => {
  let jobs = incomingJobs;
  state.changes.created.map(createdJob => jobs.find(job => createdJob.id === job.id) || jobs.concat([createdJob]));
  state.changes.updated.map(updatedJob => jobs = jobs.map(job => updatedJob.id === job.id ? updatedJob : job));
  state.changes.finished.map(finishedJob => jobs = jobs.filter(job => finishedJob.id !== job.id));

  return { jobs: jobs, fetching: false };
}

const addJob = (state, job) => {
  let jobs = state.jobs.concat([job]);
  if (state.fetching === true) { state.changes.created.push(job) }
  return {...state, jobs: jobs };
}

const updateJob = (state, job) => {
  let jobs = state.jobs.map(existingJob => existingJob.id === job.id ? job : existingJob);
  if (state.fetching === true) { state.changes.updated.push(job) }
  return {...state, jobs: jobs };
}

const finishJob = (state, job) => {
  let jobs = state.jobs.filter(existingJob => existingJob.id !== job.id);
  if (state.fetching === true) { state.changes.finished.push(job) }
  return {...state, jobs: jobs };
}
