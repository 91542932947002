import axios from 'axios';
import { set } from 'lodash';

export const replaceWindowPropertyFromResponse = (windowPropertyDataURL, windowPropertyName) => {
  if (!windowPropertyDataURL || !windowPropertyName) {
    return;
  }

  axios
    .get(windowPropertyDataURL)
    .then(response => {
      set(window, windowPropertyName, response.data);
    })
    .catch(error => {
      if (error.response && error.response.status == 401) {
        location.reload();
      } else {
        throw error;
      }
    });
}