var disableUnlessChanged = function() {
  const $form = $('.disabled-unless-changed-js');

  // Typically hidden elements using a third party styled library
  const CUSTOM_WATCHED_INPUTS =
    "#tags-select input[name='user[tag_ids][]'], .react-select input, textarea[data-editor], textarea#g-recaptcha-response";

  if (!$form.length) {
    return;
  }

  const validFormPresence = function($form) {
    const inputs = $form.find(
      '.validate-presence-js, input.required:enabled, textarea.required:enabled, select.required:enabled, .required-disabled-js'
    );

    if (!inputs.length) {
      return true;
    }

    return !inputs.filter(function(index) {
      return inputs[index].value.length === 0;
    }).length;
  };

  const $submit = $form.find(':submit');
  const originalState = $form
    .find(':input:not(:hidden), select:not(:hidden), ' + CUSTOM_WATCHED_INPUTS)
    .serialize();

  const formChangeCallback = function(e) {
    const currentState = $(e.currentTarget)
      .find(
        ':input:not(:hidden), select:not(:hidden), ' + CUSTOM_WATCHED_INPUTS
      )
      .serialize();

    $submit.prop(
      'disabled',
      !validFormPresence($(e.currentTarget)) || currentState === originalState
    );
  };

  window.manuallyTriggerFormChange = function() {
    if ($form)
      $form.get(0).dispatchEvent(new Event('change'))
  }

  const formEvents = 'keyup change click dp.change';

  $form.on('submit', function() {
    $form.off(formEvents);
  });
  $form.on(formEvents, formChangeCallback);

  $submit.prop('disabled', true);
};
document.addEventListener('DOMContentLoaded', disableUnlessChanged);
