import axios from 'axios';

export default () => {
  let tokenMeta = document.getElementsByName('csrf-token')[0];
  let token = tokenMeta && tokenMeta.getAttribute('content');
  let api = axios.create({
    headers: { 'X-CSRF-Token': token, Accept: 'application/json' },
  });
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status == 401) {
        location.reload();
      }
      throw error;
    }
  );
  return api;
};
