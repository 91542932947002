import { connect } from 'react-redux';
import JobLabel from '../components/JobLabel';
import { subscribeJob, unsubscribeJob, fetchJobs } from '../actions/delayedJobActionCreators';

const jobContainingObjects = (objects = [], jobs = []) => {
  if (!jobs) return;

  return jobs.find((job) =>
    job.status !== 'failed_obsolete' && objects.find(object => job.object_global_id === object));
}

const mapStateToProps = (state, ownProps) => ({ job: jobContainingObjects(ownProps.objects, state.jobs) })
const mapDispatchToProps = { subscribeJob, unsubscribeJob, fetchJobs };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobLabel);
