import React from "react";
import { Provider } from "react-redux";
import DelayedJobContainer from "../containers/DelayedJobContainer";
import ReactOnRails from "react-on-rails";

export default props => {
  const store = ReactOnRails.getStore("delayedJobStore");

  return (
    <Provider store={store}>
      <DelayedJobContainer {...props} />
    </Provider>
  );
};
