import React from 'react';
import Spinner from './Spinner';

export default props => {
  let statusClass = 'success';
  let text = props.loadedText;
  let spinner = false;

  if (!props.job) {
    true;
  } else if (props.job.status == 'failed') {
    statusClass = 'danger';
    text = props.failedText;
    spinner = false;
  } else if (props.job) {
    statusClass = 'warning';
    text = props.loadingText;
    spinner = true;
  }

  return (
    <div className={`alert alert-${statusClass}`}>
      {text}
      {spinner && <Spinner />}
    </div>
  );
};
