import * as ActionCable from "@rails/actioncable"
import { find } from 'lodash';

export const ACTION_CABLE_ACTION = 'ACTION_CABLE_ACTION';

/**
 * Assists in building an action cable connection and handling the creation and persistence of the connection.
 * Ideally this connection is initialized and forgotten, but allows for unsubscribing when needed.
 *
 * If identical subscriptions would be added, then we do not make a new subscription.
 * This behavior is better for redux store updates, so there isn't n actions to update the store.
 *
 * @returns {function(store: {dispatch: *}): function(function): function(function | object): (*)}
 */
export default function cableMiddleware() {
  const cable = ActionCable.createConsumer('/cable');

  return ({ dispatch }) => next => (action) => {
    if (typeof(action) === 'function') return next(action)
    if (action.type !== ACTION_CABLE_ACTION) return next(action)

    let { channel, room, unsubscribe, received, disconnected, connected } = action;

    // Requires a channel.
    if (!channel) throw 'Must define a channel';

    const subscription = find(
      cable.subscriptions.subscriptions,
      sub => sub.identifier === JSON.stringify({ channel, room }),
    );

    if (unsubscribe) return cable.subscriptions.remove(subscription);
    if (subscription) return;

    if (typeof(received) === 'string') received = result => dispatch({ type: received, result })
    if (typeof(disconnected) === 'string') received = result => dispatch({ type: received, result })
    if (typeof(connected) === 'string') received = result => dispatch({ type: received, result })

    return cable.subscriptions.create({ channel, room }, { received, disconnected, connected });
  };
}