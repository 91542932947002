/**
 * Initializes the Datetime pickers
 * @see https://getdatepicker.com/5-4/Usage/#no-icon-input-field-only
 *
 * Workaround for a bug:
 * Must use a class other than datetimepicker-input before this is initalized due to the listeners.
 * Here, we add the datetimepicker-input class after initalization to prevent call stack issues.
 * Issue was seen for prepopulated datetimepicker inputs like Event edit.
 * @see https://github.com/tempusdominus/bootstrap-4/issues/39
 */
$(() => {
  let datetimepickers = $(".datetimepicker");
  datetimepickers.datetimepicker({
    sideBySide: true,
    useCurrent: false,
    format: 'MM/DD/YYYY h:mm A',
    showClose: true,
    widgetPositioning: {
      vertical: "bottom"
    }
  });
  datetimepickers.addClass('datetimepicker-input');
})