import React from 'react';

export default ({ job, showJobLink, primaryObjectType, primaryObjectId }) => {
  if (!job) {
    return '';
  }

  const statusText = job.status === 'failed' ? 'error' : 'processing';
  const labelText = showJobLink ? (
    <a
      target="_blank"
      href={`/admin/jobs?object_type=${primaryObjectType}&object_id=${primaryObjectId}`}
    >
      {statusText}
    </a>
  ) : (
    statusText
  );
  const titleText = job.status === 'failed' ? job.friendly_error : null;

  const alertTag = titleText ? (
    <span>
      &nbsp;
      <i
        className="job-info-icon bi bi-exclamation-circle-fill"
        title={titleText}
      />
    </span>
  ) : (
    ''
  );

  return (
    <React.Fragment>
      <span
        className={`job-label badge text-bg-${job.status === 'failed' ? 'danger' : 'warning'}`}
        title={titleText}
      >
        {labelText}
      </span>
      {alertTag}
    </React.Fragment>
  );
};
