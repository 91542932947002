import ReactOnRails from 'react-on-rails';
import '../config/numeral_locale.js';
import 'tablesaw/dist/tablesaw.jquery.js'
import 'tablesaw/dist/tablesaw-init.js'
import 'tablesaw/dist/tablesaw.css'
import '../styles/tablesaw-custom.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './shared/datetimepicker-load.js';
import './shared/disabled-unless-changed.js';

// Defined in Application Helper
import DelayedJobApp from '../bundles/DelayedJob/startup/DelayedJobApp';
import delayedJobStore from '../bundles/DelayedJob/store/delayedJobStore';

ReactOnRails.register({ DelayedJobApp });
ReactOnRails.registerStore({ delayedJobStore });
